<template>
  <div class="error">
    <img src="@/assets/images/wuxiao.svg" alt="" width="500" height="500">
      {{ message }}
  </div>
</template>

<script>
import {
  getInfo,
} from '@/utils/api'
export default {
  name: "error",
  components: {},
  data() {
    return {
      message:''
    }
  },
  created() {
    var that = this;
    if (that.$route?.query?.type === '2') {
      that.message = '无效链接'
    } else {
      that.message = '当前网站暂时无法访问'
    }
    that.getList()
  },
  methods: {
    getList() {
      const lastPart = this.$Cookie.get('distributor')
      getInfo(lastPart).then(res => {
        if (res?.data?.systemConfig?.webSwitch === 0) {
          this.$router.push("/error")
        } else {
          this.$router.push("/")
        }
      })
    }
  },
  computed: {},
}
</script>
<style  lang="scss" scoped>
.error {
  
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 30px;
  display: flex;
  color: #cccccc;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>