import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookie from 'js-cookie'


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/styles/index.scss' // global css
Vue.use(ElementUI)
Vue.prototype.$Cookie = Cookie
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
