<template>
  <div>
    <!--  顶部导航栏-->
    <div class="ysnavbar" :style="{ 'background': navBackground }">
      <div class="logo-outer-box">
        <div class="logo-outer nav">
          <div class="logo-inside" @click="showFlag = 1" v-if="webImg">
            <div class="logo-img-box">
              <img :src="webImg" alt="logo">
            </div>
          </div>
          <div class="logo-inside" @click="showFlag = 1" v-else>
            <div class="logo-img-box">
              <img src="../../assets/images/logo.jpg" alt="logo">
            </div>
          </div>
        </div>
        <p @click="showFlag = 1" class="company-name">{{ webName || '分销商网站'}}</p>
      </div>
      <ul class="nav-menu">
        <li @click="skipTo('#profile')">产品和服务</li>
        <li @click="skipTo('#development-solve')">行业解决方案</li>
        <li @click="skipTo('#enterprise-culture')">公司信息</li>
        <li @click="linkHerf">商城</li>
      </ul>
      <div class="nav-help">
        <ul class="nav-menu">
          <li @click="callOur">联系我们</li>
          <li @click="showFlag = 3" style="margin-right: 0 !important;">招聘</li>
        </ul>
        <div class="custom-dropdown">
          <div style="">
            <!--            <span @click="login" style="color: white;display: flex;justify-content:end;flex-wrap: nowrap;font-size: 15px;cursor: pointer">{{ loginName }}</span>-->
            <div v-if="!userLoginPhone">
              <span class="el-dropdown-link" style="color: white;">
                <span @click="login"
                  style="width: 125px;overflow: hidden;color: white;display: flex;justify-content:end;flex-wrap: nowrap;font-size: 15px;cursor: pointer;margin-right: 10px;">{{ loginName }}</span>
                <span class="userimg"><img style="width: 100%;height: 100%"
                    :src="avatarImg ? avatarImg : require('@/assets/images/user.png')" alt="user"></span><i
                  class="el-icon-arrow-down el-icon--right"></i>
              </span>
            </div>
            <el-dropdown v-if="userLoginPhone" trigger="click" style="height: 45px;display: flex;">
              <span class="el-dropdown-link" style="color: white;">
                <span @click="login"
                  style="width: 125px;overflow: hidden;color: white;display: flex;justify-content:end;flex-wrap: nowrap;font-size: 15px;cursor: pointer;margin-right: 10px;">{{ loginName }}</span>
                <span class="userimg"><img style="width: 100%;height: 100%"
                    :src="avatarImg ? avatarImg : require('@/assets/images/user.png')" alt="user"></span><i
                  class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item style="color: black;" @click.native="loginout">
                  <img src="../../assets/images/u199.svg" alt=""
                    style="width: 13px; height: 13px; margin-right: 5px;" />
                  安全退出
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>

        </div>
      </div>
    </div>
    <!--  body-->
    <div class="ys-body" v-if="showFlag === 1">
      <!--  轮播器-->
      <div class="carousel" style="background: #d3dce6;">
        <el-carousel indicator-position="outside" arrow="always" style="width: 100%;height:760px;">
          <el-carousel-item v-for="item in webData?.carousels" :key="item.imgUrl">
            <img v-if="item.imgUrl" :src="item.imgUrl" alt="carousel" style="width: 100%;height:100%;">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="background: #EDF5FD;padding-top: 50px">
        <div class="company-profile">
          <div class="profile" id="enterprise-culture">
            <p style="text-align: center;font-size: 30px;font-weight: 650;color: #555555;">公司简介</p>
            <div class="top">
              <div class="top-center">
                <div class="lunbo">
                  <img ref="introduce_img" v-if="webData.webInfo.coverImgUrl" :src="webData.webInfo.coverImgUrl"
                    alt="cphoto">
                  <div ref="introduce_img" v-else style="width: 100%;height: 100%;border: 1px solid #cccccc ;"></div>
                </div>
                <div class="introduce">
                  <p
                    style="margin: 0px 0 17px 0;font-weight: bold;font-size: 22px;color: #48484D;position: absolute;top: 0;">
                    {{ webData.webInfo.corporateName }}</p>
                  <div
                    style="font-weight: bold;font-size: 15px;color: #5A5A5A;line-height: 2;width: 100%;overflow: auto;position: absolute;bottom: 0;height: calc(100% - 50px);word-break:break-all;"
                    v-html="formattedText"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="hotpoint">
            <!--          <p style="text-align: center;font-size: 28px;font-weight: bolder;color:#555555;margin-bottom: 50px;">最新热点</p>-->
            <p style="text-align: center;font-size: 30px;font-weight: 650;margin-bottom: 50px;color: #555555;">最新热点</p>
            <div class="hot-img">
              <div v-for="item in webData.advertises" class="hot-img-box">
                <img v-if="item.imgUrl" :src="item.imgUrl" :key="item.imgUrl" alt="advImg"
                  style="width: 100%;height: 100%;">
              </div>

            </div>
          </div>

          <div class="solution" id="development-solve">
            <p style="text-align: center;font-size: 30px;font-weight: bolder;color: #555555;">行业解决方案</p>
            <div class="cad">

              <div v-for="(item, i) of webData.industrySolutionsList" :key="i" class="card">
                <div class="card-top">
                  <div class="card_top_img_box">
                    <img v-if="item.promotionalImgUrl" :src="item.promotionalImgUrl" alt="industryImg">
                    <img v-else src="@/assets/images/noneData.svg" alt="">
                  </div>
                  <div style="padding: 10px 30px;">
                    <p v-html="item.programmeName" style="height: 35px;font-weight: bold;font-size: 16px" />
                    <p v-html="item.programmeProfile" style="height: 100px;font-size: 13px;color: #606266" />
                  </div>
                </div>
              </div>
              <div class="addcard"
                v-if="webData.industrySolutionsList.length>0 && webData.industrySolutionsList.length%3 === 0  ?  false:true">
              </div>
            </div>
          </div>
          <div class="product" id="profile">
            <p style="text-align: center;font-size: 30px;font-weight: 650;margin:111px 0 30px 0;color: #555555;">产品和服务
            </p>
            <div class="card-pro">
              <div class="pro-type" v-for="item in productTip" :key="item.productCategoryLevel1Id">
                <p class="pro_name" :style="{ 'background': navBackground }">{{ item.productCategoryLevel1 }}</p>
                <p class="pro_tip" :style="{ 'background': navBackground }">&lt;&lt;MORE&gt;&gt</p>
                <div style="display: flex;flex-wrap: nowrap;border: 1px solid #EBEEF5;background: white"
                  v-if="item.productList">
                  <div class="presentation_left">
                    <p style="color: #FF0066;font-size: 14px;text-align: center;padding:10px 40px 10px 2  0px;">
                      {{ item.productList[0].brand }}&nbsp;&nbsp;&nbsp;{{ item.productList[0].series }}</p>
                    <p style="color: #555555;font-size: 14px;text-align: center;padding: 20px">
                      {{ item.productList[0].productCategory }}</p>
                    <p style="color: #FF0066;font-size: 14px;text-align: center;padding: 20px"><span
                        style="color:#ffc700;">￥</span>{{ item.productList[0].benchmarkPrice }}元起</p>
                  </div>
                  <div class="presentation_right" style="position: relative">
                    <div style="position: absolute;width: 68px;height: 40px;left: -34px">
                      <img v-if="item.productList[0].brandImgUrl" :src="item.productList[0].brandImgUrl" alt="proimg"
                        style="width: 100%;overflow: hidden;height: 100%">
                    </div>
                    <div style="display: inline-block;width: 200px;height: 200px">
                      <img v-if="item.productList[0].img1Url" :src="item.productList[0].img1Url" alt="proimg"
                        style="width: 100%;overflow: hidden;height: 100%">
                    </div>
                  </div>
                </div>
              </div>
              <div class="pro-type" v-if="productTip.length>0 && productTip.length/3 === 0  ?  false:true"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFlag !== 1" style="padding-top: 50px;">
      <call-me v-if="showFlag === 2" :contents="content"></call-me>
      <recruit v-if="showFlag === 3" :invites="invite"></recruit>
    </div>

    <bottom-info :message="webData" @jump="skipTo"></bottom-info>

  </div>
</template>

<script>
import bottomInfo from "@/views/job/bottomInfo.vue";
import callMe from "@/views/job/callme.vue";
import recruit from "@/views/job/recruit.vue"
import { getInfo, getProduct, getUserInfo, userLoginOut, getWebInfoById } from "@/utils/api";
// import Cookie from "core-js/internals/queue";

export default {
  name: "website",
  components: { bottomInfo, callMe, recruit, },
  data() {
    return {
      navOffsetTop: 0,
      //  背景色
      navBackground: '#0079FE',
      // 联系我们
      content: [],
      // 登录名切换
      loginName: '登录',
      avatarImg: require('@/assets/images/user.png'),
      webImg: '',
      webName: '',
      mallUrl:'',
      webImgReady: require('@/assets/images/logo.jpg'),
      invite: [],
      // 轮播循环图
      carouselImg: [
        { id: 0, img: require("@/assets/images/u244.png") },
        { id: 1, img: require("@/assets/images/u246.png") },
        { id: 2, img: require("@/assets/images/u248.png") },
        { id: 3, img: require("@/assets/images/u250.png") },
      ],
      // 最新热点图片
      hotImg: [
        { id: 1, img: require("@/assets/images/u296.svg") },
        { id: 2, img: require("@/assets/images/u297.svg") },
        { id: 3, img: require("@/assets/images/u298.svg") },
      ],
      compImg: require("@/assets/images/u206.png"),
      //   产品信息
      productTip: [

      ],
      //  公司信息
      brieffrom: {
        name: '因斯科技有限公司',
        beirf: '\t\t因斯科技专注于工业互联网行业，专业服务工业中小企业，宗旨是“让数字化更普惠，让智能更懂工业”。\n' +
          '\t\t公司团队具有多年工业和互联网从业经历，团队成员曾任职于国际知名工业电气公司和互联网公司，对国内外工业数字化发展现状及未来方向具有多年研究，同时也熟悉国内工业市场业务流程和发展规律，持续专注于工业数字化领域，立志于助力中小工业企业在智能时代低成本享受数智科技带来的产业革命，逐步完成工业4.0的转型升级。'
      },
      webData: {
        advertises: [
        ],
        carousels: [
        ],
        industrySolutionsList: [
        ],
        webContactInfoList: [
        ],
        webInfo: {
          companyProfile: "",
          corporateName: "",
          coverImgId: 0,
          coverImgUrl: "",
          serviceHotline: "",
          serviceTime: "",
          webInfoId: 0
        },
        webJobRecruitmentList: [
        ]
      },
      showFlag: 1,
      userLoginPhone: null,
      //   分销商标识
      // distributor: '9d019b75'
      distributor: '',
      userId: null
    }
  },
  created() {
    // this.getList(),
    //   this.getProList()

  },
  mounted() {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const lastPart = url.searchParams.get('code');
    const webInfoId = url.searchParams.get('webInfoId')
    console.log(webInfoId, 'webInfoId');
    console.log(process.env.NODE_ENV, 'process.env.NODE_ENV');
    if (webInfoId) {
      this.getWebInfoById(webInfoId)
    } else {
      this.extractLastPathSegment()
    }




    // this.getProList()
    this.getUserPhone()
    // this.getList(),
    // 监听滚动事件
    window.addEventListener('scroll', this.fiexdNav)
    this.getData()
    // 获取url
    // const protocol = window.location.protocol; // 协议
    // const host = window.location.host; // 域名
    // const path = this.$route.path; // 路径
    // this.distributor =
    // console.log(protocol+host);
  },
  beforeDestroy() {
    // 移除监听器
    window.removeEventListener('scroll', this.fiexdNav)
  },
  methods: {
    extractLastPathSegment() {
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      const lastPart = url.searchParams.get('code');
      if (lastPart) {
        this.$Cookie.set('distributor', lastPart)
        this.distributor = lastPart
        this.getList()
      } else {
        this.distributor = this.$Cookie.get('distributor')
        this.getList()
      }
    },
    getList() {
      if (this.distributor) {
        getInfo(this.distributor).then((res) => {
          if (res.data) {
            if (res.data?.systemConfig?.webSwitch === 0) {
              this.$router.push("/error")
            } else {
              this.webData = res.data
              const keyToCheck = 'imgId'
              const arr = JSON.parse(JSON.stringify(res.data?.carousels))
              if (arr && arr.length > 0) {
                this.webData.carousels = arr.filter(obj => obj[keyToCheck] !== '' && obj[keyToCheck] !== 0 && obj[keyToCheck] !== null)
              } else {
                this.webData.carousels = res.data?.carousels
              }
              this.content = res.data.webContactInfoList
              this.invite = res.data.webJobRecruitmentList
              this.webImg = res.data.systemConfig.webLogoUrl
              this.webName = res.data.systemConfig.webName
              if (res.data?.systemConfig?.themeColor) this.navBackground = res.data?.systemConfig?.themeColor
            if (res.data?.systemConfig?.mallUrl) this.mallUrl = res.data?.systemConfig?.mallUrl
              this.$Cookie.set("distributorId", res.data.distributorId);
              
              this.getProList(res.data.distributorId)
            }

          } else {
            this.$alert('请先前往分销商后台配置网站信息', '', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'info',
                  message: `action: ${action}`
                });
              }
            });
          }

        })
      } else {
        if (process.env.NODE_ENV !== 'development') {
          this.$router.push({ path: '/error', query: { type: '2' } })
        }
      }

    },
    // 通过id来查 (预览)
    getWebInfoById(webInfoId) {
      if (webInfoId) {
        getWebInfoById(webInfoId).then(res => {
          if (res.data) {
            this.webData = res.data
            this.content = res.data?.webContactInfoList
            this.invite = res.data?.webJobRecruitmentList
            this.webImg = res.data?.systemConfig?.webLogoUrl
            this.webName = res.data?.systemConfig?.webName
            if (res.data?.systemConfig?.themeColor) this.navBackground = res.data?.systemConfig?.themeColor
            if (res.data?.systemConfig?.mallUrl) this.mallUrl = res.data?.systemConfig?.mallUrl
            this.$Cookie.set("distributorId", res.data?.data?.distributorId);
            this.getProList(res.data?.distributorId)
          } else {
            if (process.env.NODE_ENV !== 'development') {
              this.$router.push({ path: '/error', query: { type: '2' } })
            }
          }
        })

      } else {
        if (process.env.NODE_ENV !== 'development') {
          this.$router.push({ path: '/error', query: { type: '2' } })
        }
      }
    },
    // 获取用户手机号
    getUserPhone() {
      this.userLoginPhone = this.$Cookie.get('userName')
      if (this.userLoginPhone) {
        let userArray = {
          phone: this.userLoginPhone,
          distributorId: this.$Cookie.get('distributorId'),
          // access_token:this.$Cookie.get('access_token')
        }
        getUserInfo(userArray).then((res) => {
          if (res.code === 200) {
            this.loginName = res.data.nickName
            this.avatarImg = res.data.avatarUrl
            this.userId = res.data.userId
          }
        })
      }

    },
    linkHerf() {
      let url = `${this.mallUrl}website/shophome?code=${this.distributor}`
      if (this.loginName !== '登录') {
        console.log("已登录，跳转商城的时候要同步登录信息");
        if (process.env.NODE_ENV !== 'development') {
          window.open(url +  `&userId=${this.userId}`, '_blank')
        }else{
          window.open(`http://localhost:8080/website/shophome/shop?code=${this.distributor}&userId=${this.userId}`, '_blank') 
        }
        // window.open(`http://ys-distributor-mall.gelanwl.com/website/shophome?code=${this.distributor}&userId=${this.userId}`, '_blank')
      } else {
        console.log("没登录,不需要同步");
        if (process.env.NODE_ENV !== 'development') {
          window.open(url, '_blank')
        }else{
          window.open(`http://localhost:8080/website/shophome/shop?code=${this.distributor}`, '_blank') 
        }
        // window.open(`http://ys-distributor-mall.gelanwl.com/website/shophome?code=${this.distributor}`, '_blank') // 在新窗口打开外链接
      }
    },
    /**
     * Date:2024/03/13
     * Author:wt
     * Function:获取热销
     * @param:分销商code标识
     */
    getProList(item) {
      if (this.distributor) {
        const tempArray = {
          distributorCompanyId: this.distributor * 1,
          distributorId: item,
        }
        getProduct(tempArray).then((res) => {
          // console.log(res.data)
          if (res.data) {
            let filteredData = res.data.filter(item => item.productList && item.productList.length > 0);
            this.productTip = filteredData
          }
        })
      }
    },
    /** 设置导航条nav到达页面顶部时固定 **/
    getData() {
      this.navOffsetTop = document.querySelector('.nav').offsetTop
    },
    fiexdNav() {
      // 2.获取当前页面的卷曲高度
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const nav = document.querySelector('.nav')
      // 3.判断卷曲高度是否大于等于导航条的offsetTop值
      if (scrollTop > this.navOffsetTop) {
        //   3.1若满足，则给nav导航添加固定样式
        nav.classList.add('fixedNav')
      } else {
        //   3.2若不满足，则删除nav导航的固定样式
        nav.classList.remove('fixedNav')
      }

      /** 当滚动到一定区域时给导航项添加选中样式 **/
      //  1.获取所有锚点元素
      const contents = document.querySelectorAll('.about-content>div')
      // 2.获取锚点元素的offsetTop值，并收集在一个数组
      const contentsOffsetTop = []
      contents.forEach(item => {
        contentsOffsetTop.push(item.offsetTop)
      })
      // 3.获取页面高度
      const pageHeight = window.innerHeight
      // 4.获取nav的子元素
      const navChildren = document.querySelectorAll('.nav li')
      // 5.遍历锚点元素的offsetTop值
      for (let i = 0; i < contentsOffsetTop.length; i++) {
        // 5.1 设置第一项导航默认为选中状态
        if (i === 0) {
          navChildren[0].classList.add('active')
        } else if (scrollTop > contentsOffsetTop[i - 1] + pageHeight / 3) {
          // 排他思想
          for (let j = 0; j < contentsOffsetTop.length; j++) {
            navChildren[j].classList.remove('active')
            navChildren[i].classList.add('active')
          }
        } else {
          navChildren[i].classList.remove('active')
        }
      }
    },
    callOur() {
      this.showFlag = 2
      window.scrollTo(0, 0)
    },
    /**
     *设置点击导航跳转到指定选择器对应的锚点元素
     * @param {*} selector
     **/
    skipTo(selector) {
      this.showFlag = 1
      setTimeout(() => {
        const navHeight = document.querySelector('.nav').offsetHeight;
        const extraSpace = 20;
        const target = document.querySelector(selector);

        if (target) {
          const offsetTopAdjusted = target.getBoundingClientRect().top + window.pageYOffset - navHeight - extraSpace;
          window.scrollTo({
            top: offsetTopAdjusted,
            behavior: 'smooth'
          });
        } else {
          console.warn('Element not found for selector:', selector);
        }
      }, 300)
      // this.$router.push("/")

    },

    /**
     * date:2024/2/22
     * author:wt
     * function:右上角登录
     * @param:无
     */
    login() {
      if (this.loginName !== '登录') {
        return false
      } else {
        this.$router.push('/login');
      }

    },
    /**
     * date:2024/2/22
     * author:wt
     * function:右上角安全退出
     * @param:无
     */
    loginout() {
      if (this.$Cookie.get('accessToken')) {
        if (this.loginName !== '登录') {
          userLoginOut().then((res) => {
            if (res.code === 200) {
              this.$message.success('退出成功')
              this.$Cookie.remove('userName');
              this.$Cookie.remove('accessToken');
              this.$Cookie.remove('expires_in');
              // localStorage.removeItem('accessToken');
              this.loginName = '登录'
              this.avatarImg = require('@/assets/images/user.png')
              this.userLoginPhone = null
              this.getList()
            }
          })
        } else {
          return false
        }
      } else {
        this.userLoginPhone = null
        this.$message.success('退出成功')
      }

    },
  },
  computed: {
    /**
     * date:2024/2/23
     * author:wt
     * function:识别文字的换行和缩进
     * @param:无
     */
    formattedText() {
      return this.webData.webInfo.companyProfile.replace(/\n/g, '<br/>').replace(/\t/g, '&emsp;');
      // return this.brieffrom.beirf.replace(/\n/g, '<br/>').replace(/\t/g, '&emsp;');
    },
  },
}
</script>

<style lang="scss">
html,
body {
  scroll-behavior: smooth;
}

.ysnavbar {
  width: 100%;
  height: 45px;
  position: fixed;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 999;
  // box-shadow: 0 0 20px 5px rgba(0, 96, 254, 0.7);

  /* 阴影效果 */
  .logo-outer-box {
    min-width: 168px;
    width: fit-content;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding-left: 10px;
    height: 45px;
    margin-right: 50px;
  }
  .logo-outer {
    width: 38px;
    height: 38px;
    // background: #73b7fe;
    margin: 0 6px 0 0px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo-inside {
      width: 38px;
      height: 38px;
      // margin: 6px 7px;
      background: #ffffff50;
      display: inline-block;
      border-radius: 50%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      //padding-top: 5px;
      //padding-left: 2px;
      .logo-img-box {
        border-radius: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #ffffff;
        img {
          border-radius: 50%;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .company-name {
    color: white;
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    display: inline-block;
  }

  .nav-menu {
    list-style-type: none;
    font-size: 15px;
    color: white;

    li {
      float: left;
      cursor: pointer;
      margin-right: 50px;
    }

    li:hover {
      text-decoration: underline;
      font-weight: bold;
    }
  }

  .nav-help {
    width: 350px;
    height: 45px;
    position: absolute;
    right: 20px;
    line-height: 45px;
    display: flex;
    .nav-menu {
      height: 45px;
    }
  }

  .userimg {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: inline-block;
    background: #e6f5ff;
    margin: 3px 0 0 3px;
    overflow: hidden;
  }

  .custom-dropdown {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .el-dropdown-link {
    height: 45px;
    display: flex;

    align-items: center;
  }
}
//.el-carousel__item img {
//  color: #475669;
//  font-size: 18px;
//  opacity: 0.75;
//  line-height: 300px;
//  margin: 0;
//}
.ys-body {
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }

  .carousel {
    margin: 45px 0 0 0;
    width: 100%;
    height: 700px;
    position: relative;

    .el-carousel__item {
      height: 700px;
    }

    .el-carousel__indicators {
      position: absolute;
      left: 46%;
    }

    .el-carousel__arrow {
      top: 100% !important;
    }
  }

  .company-profile {
    width: 85%;
    margin: 0 auto;

    .profile {
    }

    .top {
      width: 100%;
      height: fit-content;
      //padding: 20px;
      margin-bottom: 100px;

      .top-center {
        margin-top: 30px;
        display: flex;
        justify-content: start;
        width: 100%;

        .lunbo {
          position: relative;
          height: 0;
          width: 30%;
          // height: 320px;
          // border: 1px solid red;
          padding-bottom: 17%;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }

        .introduce {
          padding-left: 80px;
          width: 69%;
          position: relative;
          height: 0;
          // height: 320px;
          // border: 1px solid red;
          padding-bottom: 17%;
        }
      }
    }

    .hotpoint {
      width: 100%;
      height: fit-content;
      margin-bottom: 100px;

      .hot-img {
        width: 100%;
        display: flex;
        // flex-wrap: nowrap;
        // justify-content: space-between;
        //padding: 40px;
        // width: 50%;
        .hot-img-box {
          position: relative;
          height: 0;
          width: 30%;
          // height: 320px;
          // border: 1px solid red;
          padding-bottom: 17%;
          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        .hot-img-box:nth-of-type(2) {
          margin: auto;
        }
      }

      // .hot-img > img {
      //   // width: 30%;
      // }
    }
  }

  .solution {
    .cad {
      width: 100%;
      height: fit-content;
      //padding: 10px 0;
      margin: 0 auto;
      display: flex;
      // justify-content: space-around;
      flex-wrap: wrap;
      align-content: flex-start;
      .addcard {
        width: calc(30% - 10px);
        height: 300px;
        margin-top: 40px;
        //margin-right: 30px;
        // margin-left: 50px;
        position: relative;
        background-color: transparent;
      }
      .card {
        width: calc(30% - 10px);
        height: 300px;
        margin-top: 40px;
        //margin-right: 30px;
        // margin-left: 50px;
        position: relative;
        background-color: #e4f0fd;

        .card-top {
          width: 100%;
          height: 300px;
          box-shadow: -4px 6px 8px rgba(0, 0, 0, 0.2);
          background-color: #fff;
          position: absolute;
          top: 10px;
          right: 10px;
          .card_top_img_box {
            border-bottom: 1px solid #ededed;
            width: 100%;
            height: 0;
            position: relative;
            height: 0;
            // height: 320px;
            // border: 1px solid red;
            padding-bottom: 38.5%;
            img {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      .card:nth-of-type(1n) {
        margin-left: 10px;
      }
      .card:nth-of-type(3n -1) {
        margin: 40px auto;
      }
      .card:nth-of-type(3n) {
        margin-right: 0;
      }
      .card:hover {
        cursor: pointer;
        background-image: linear-gradient(to left, #0079fe, #e4f0fd);
      }
    }
  }

  .card-pro {
    width: 100%;
    margin: 0 auto;
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    // align-content: flex-start;

    .pro-type {
      width: 30%;
      height: 250px;
      margin-bottom: 50px;
    }
    .pro-type:nth-of-type(3n -1) {
      margin: 0px auto;
    }
    .pro_name {
      text-align: center;
      font-size: 18px;
      color: white;
      padding: 5px 5px 0 5px;
    }

    .pro_tip {
      text-align: center;
      font-size: 13px;
      color: white;
      padding-bottom: 5px;
    }
    .presentation_left {
      padding: 20px 0;
      width: calc(100% - 200px);
    }
    .presentation_right {
      width: 200px;
      height: 200px;
    }
  }
}
</style>
