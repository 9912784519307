<template>
  <div class="register">
    <el-form ref="queryParams" :model="queryParams" :rules="loginRules" class="login-form">
      <h3 class="title">
        完善账号信息
      </h3>
      <el-row :gutter="20">
        <el-col :span="12" class="paddingRight15">
          <el-form-item prop="nickName">
            <el-input v-model="queryParams.nickName" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="paddingLeft15">
          <div
            style="width: 100%;height: 30px;display: flex;flex-wrap: nowrap;align-items: center;font-size: 12px;color: #666666;">
            <UserAvatar ref="userAvatar" :url="logoCodeUrl" @back="handelSetAvatarBack" />
            <span>设置头像：</span>
            <!-- <img :src="userLogo" alt="logo" @click="changeImg"
              style="display: inline-block;width: 30px;height: 30px;border-radius: 50%"> -->
            <div class="tx" :class="[logoCodeUrl?'txc': '']" @click="handelSetAvatar">
              <template v-if="logoCodeUrl">
                <img :src="logoCodeUrl" />
              </template>
              <template v-else>
                <img alt="" src="@/assets/user.svg" />
                <!-- <img src="@/assets/images/user.png" /> -->
              </template>
            </div>
          </div>

        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="12" class="paddingRight15">
          <el-form-item prop="firstPassWord">
            <el-input type="password" v-model="queryParams.firstPassWord" placeholder="请输入密码" show-password></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" class="paddingLeft15">
          <el-form-item prop="password">
            <el-input type="password" v-model="queryParams.password" placeholder="确认密码" @change="affirmPass"
              show-password></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="12" class="paddingRight15">
          <el-form-item prop="companyName">
            <!-- <el-input v-model="queryParams.companyName" placeholder="请输入公司名称"></el-input> -->
            <el-autocomplete v-model="queryParams.companyName" class="inline-input" :fetch-suggestions="querySearch"
              placeholder="输入公司名称" :trigger-on-focus="false" auto-complete="off" style="width: 100%;"
              @select="handleSelect" />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="paddingLeft15">
          <div style="display: flex;flex-wrap: nowrap;align-items: center;font-size: 12px;color: #666666;">
            <p style="padding-top: 8px;"><span>信用代码：</span><span>{{queryParams.creditCode}}</span></p>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="12" class="paddingRight15">
          <el-form-item prop="deptType">
            <el-select v-model="queryParams.deptType" placeholder="请选择部门" style="width: 100%">
              <el-option v-for="item in optionsdept" :key="item.dictValue" :label="item.dictLabel"
                :value="item.dictValue">
              </el-option>
            </el-select>

          </el-form-item>
        </el-col>
        <el-col :span="12" class="paddingLeft15">
          <el-form-item prop="positionType">
            <el-select v-model="queryParams.positionType" placeholder="请选择职务" style="width: 100%">
              <el-option v-for="item in options" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue">
              </el-option>
            </el-select>

          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="">
        <el-col :span="12" class="paddingRight15">
          <el-form-item prop="cityCode">
            <CityList ref="cityList" :max-len="2" size="mini" format="/" @change="getCity" />
            <!-- <el-cascader style="width: 52%" :options="provinceAndCityData()" v-model="queryParams.cityCode"
              placeholder="所在城市" prefix-icon="el-icon-eleme" @change="handleCity">
            </el-cascader> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 40px">
        <el-col :span="24" style="text-align: center">
          <el-button type="primary" style="width: 50%;" @click="loginRe">注册完成</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
const getUploadBidDocument = (options = {}) => {
  const oNode = document.createElement('input')
  oNode.type = 'file'
  oNode.accept = options.accept || 'image/jpeg,image/jpg,image/png'
  oNode.style.display = 'none'
  oNode.id = 'img' + Date.now()
  const body = document.body
  body.append(oNode)
  setTimeout(() => {
    oNode.click()
    oNode.onchange = event => {
      const files = event.target.files
      if ('change' in options) {
        options.change(files)
      }
      const oInput = document.querySelector('#' + oNode.id)
      body.removeChild(oInput)
    }
  }, 100)
}
import upload from '@/utils/upload'

import { getProduct, userRegister, getDept, oneCompanyInfo, companyName,oneCompanyInfoNew } from "@/utils/api";

import CityList from '@/components/CityList/CityList.vue';

import UserAvatar from './userAvatar.vue'
import {
  provinceAndCityData,
  pcTextArr,
  regionData,
  pcaTextArr,
  codeToText,
} from "element-china-area-data";

export default {
  name: "register",
  props: {
    phoneNumber: String,
  },
  components: {
    CityList,
    UserAvatar
  },
  data() {
    return {
      firstPassWord: '',
      // 信用代码
      // cityCode:[],
      passFlag: 0,
      userLogo: require('@/assets/images/user.png'),
      queryParams: {
        firstPassWord: '',
        nickName: '',
        avatar: '',
        password: '',
        positionType: '',
        companyName: '',
        provinceCode: '',
        cityCode: '',
        deptType: '',
        creditCode: '',
        scope: '',
        address: '',
      },
      loginRules: {
        nickName: [
          { required: true, trigger: 'blur', message: '请输入您的姓名' }
        ],
        firstPassWord: [
          { required: true, trigger: 'blur', message: '请输入您的密码' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的密码' }
        ],
        deptType: [
          { required: true, trigger: 'change', message: '请选择部门' }
        ],
        companyName: [
          { required: true, trigger: 'blur', message: '请输入公司名称' }
        ],
        positionType: [
          { required: true, trigger: 'change', message: '请选择职务', type: 'string' }
        ],
        cityCode: [
          { required: true, trigger: 'change', message: '请选择城市' }
        ],
      },
      options: [
        { label: '采购经理', value: '1' },
        { label: '采购员', value: '2' },
        { label: '技术总工程师', value: '3' },
        { label: '技术员', value: '4' },
        { label: '其他', value: '0' },
      ],
      optionsdept: [],
      logoCodeUrl:'',
    }
  },
  mounted() {
    this.getlist()
  },
  methods: {
    getlist() {
      const zhiwei = 'sys_customer_position'
      const bumen = 'sys_customer_dept'
      getDept(zhiwei).then(res => {
        this.options = res.data
      })
      getDept(bumen).then(res => {
        this.optionsdept = res.data
      })
    },
    querySearch(queryString, cb) {
      const data = {
        keyword: queryString,
        skip: 0
      }
      companyName(data).then(res => {
        if (res.data.items.length > 0) {
          const arr = []
          res.data.items.map(item => {
            arr.push({
              value: item.name,
              label: item.name
            })
          })
          cb(arr)
        } else {
          cb([])
        }
      })
    },
    getCity(res) {
      let cityCode = res.path
      let cityName = res.pathLabels
      this.queryParams.provinceName = cityName[0]
      this.queryParams.provinceCode = cityCode[0]
      this.queryParams.cityName = cityName[1]
      this.queryParams.cityCode = cityCode[1]
    },
    // 公司选择完成触发
    handleSelect(item) {
      const data = {
        keyword: item.value
      }
      oneCompanyInfoNew(data).then(res => {
        this.queryParams.creditCode = res.data.creditNo
        this.queryParams.scope = res.data.scope
        this.queryParams.address = res.data.address
      })
    },
    provinceAndCityData() {
      return provinceAndCityData
    },
    /**
     * Date:2024/03/19
     * Author:wt
     * Function:用户注册信息
     * @param:queryParams
     */
    loginRe() {
      this.$refs.queryParams.validate(valid => {
        if (valid && this.passFlag === 0) {
          if(!this.queryParams.avatar) return this.$message.error('请设置头像')

          this.queryParams.phonenumber = this.phoneNumber
          this.queryParams.userName = this.phoneNumber
          this.queryParams.distributorId = this.$Cookie.get('distributorId')
          this.queryParams.distributorCompanyId = this.$Cookie.get('distributorCompanyId') || 0
          userRegister(this.queryParams).then((res) => {
            if (res.code === 200) {
              this.$emit('handlePath', 2)
            } else {
              this.$message.error(res.msg)
            }
          })
        }
      })
    },
    /**
     * Date:2024/3/18
     * Author:wt
     * Function:密码对比确认
     * @param:无
     */
    affirmPass() {
      if (this.queryParams.firstPassWord === '' || this.queryParams.firstPassWord !== this.queryParams.password) {
        this.$message.error('两次输入密码不一致')
        this.passFlag = 1
      } else if (this.queryParams.firstPassWord === this.queryParams.password) {
        this.passFlag = 0
      }
    },
    handleCity(item) {
      this.queryParams.cityName = codeToText[item[1]]
      this.queryParams.provinceName = codeToText[item[0]]
      this.queryParams.provinceCode = item[0]
      this.queryParams.cityCode = item[1]
    },
    /** 系统上传图片 */
    changeImg() {
      // const sysId = this.tableData.systemConfigId
      const vm = this
      getUploadBidDocument({
        change(files) {
          const file = files[0]
          upload(file, {
            resourceType: 1,
            module: 'fs_manage',
            progress(res) {
              // console.log(res,'123123')
            }
          })
            .then(res => {
              // console.log(res,'123123')
              vm.userLogo = res.data.resourceUrl
              vm.queryParams.avatar = res.data.resourceId

              // const sysImg= res.resourceUrl
              // const sysLogoObject = {
              //   systemLogo: res.resourceId,
              //   systemConfigId:sysId
              // }
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
    },

    handelSetAvatar() {
      this.$refs.userAvatar.editCropper()
    },
    handelSetAvatarBack(res) {
      const { data } = res || {}
      this.logoCodeUrl = data.resourceUrl
      // this.queryParams.logoCode = data.resourceUrl
      this.queryParams.avatar = data.resourceId
    }
  },
  watch: {
    // 'queryParams.password'(newValue,oldValue){
    //   if(this.queryParams.firstPassWord === newValue){
    //     this.passFlag = 0
    //   }else{
    //     this.passFlag = 1
    //     this.$message.error('两次输入密码不一致')
    //   }
    // },
  }
}
</script>

<style lang="scss" scoped>
.register {
  //::v-deep .el-input--suffix{
  //  width: 100% !important;
  //}
  .login-form {
    background: #ffffff;
    width: 700px;
    height: 600px;
    box-shadow: 0px 0px 6px rgb(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 50px;
    margin: 130px auto;
    //.el-form-item{
    //  margin-bottom: 32px;
    //  .el-form-item__content{
    //    line-height: 36px;
    //  }
    ::v-deep .el-input__inner {
      height: 30px !important;
      width: 100%;
    }
    .title {
      margin: 0px auto 30px auto;
      text-align: center;
      font-size: 20px;
      color: #606266;
    }
    //}
    //.el-input {
    //  height: 36px;
    //  input {
    //    height: 36px;
    //  }
    //}
    //.input-btn{
    //  font-size: 12px;
    //  padding: 0 6px 0 14px;
    //  color: #909399;
    //  display: inline-block;
    //  &.w90{
    //    width: 120px;
    //    text-align: center;
    //  }
    //}
    //.el-select .el-input {
    //  width: 76px;
    //}
    //.input-with-select .el-input-group__prepend {
    //  background-color: #fff;
    //}
  }
}
::v-deep .el-input {
  // width: 290px !important;
}
.paddingRight15 {
  padding-right: 15px !important;
}
.paddingLeft15 {
  padding-left: 15px !important;
}
.tx {
  width: 60px;
  height: 60px;
  // border: 1px solid #DCDFE6;
  background: rgba(230, 245, 255, 1);
  border-radius: 100%;
  padding: 2px;
  margin-left: 50px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
}
.txc {
  border-radius: 50px;
  border: 1px solid #0079fe;
  overflow: hidden;
}
</style>